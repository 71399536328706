.Home {
  position: relative;

  &-leaf {
    position: fixed;
    height: 150px;
    width: auto;
    z-index: 3;
    filter: hue-rotate(300deg);

    &--top-left {
      left: 0px;
      top: 0px;
      transform: scale(-1, -1);

      @media only screen and (min-width: 1024px) {
        height: 350px;
      }

      @media only screen and (min-width: 1440px) {
        height: 550px;
      }
    }

    &--top-right {
      right: 0px;
      top: 0px;
      transform: scaleY(-1);

      @media only screen and (min-width: 1024px) {
        height: 350px;
      }

      @media only screen and (min-width: 1440px) {
        height: 550px;
      }
    }

    &--bottom-left {
      left: 0px;
      top: calc(100vh - 150px);
      transform: scaleX(-1);

      @media only screen and (min-width: 1024px) {
        height: 350px;
        top: calc(100vh - 350px);
      }

      @media only screen and (min-width: 1440px) {
        height: 550px;
        top: calc(100vh - 550px);
      }
    }

    &--bottom-right {
      right: 0px;
      top: calc(100vh - 150px);

      @media only screen and (min-width: 1024px) {
        height: 350px;
        top: calc(100vh - 350px);
      }

      @media only screen and (min-width: 1440px) {
        height: 550px;
        top: calc(100vh - 550px);
      }
    }
  }
}

.Bar {
  position: fixed;
  z-index: 3;
  background-color: $pink-color;

  &--top {
    top: 10px;
    width: calc(100% - 20px);
    height: 15px;
    left: 10px;
  }

  &--left {
    top: 10px;
    width: 15px;
    height: calc(100vh - 20px);
    left: 10px;
  }

  &--bottom {
    top: calc(100vh - 25px);
    width: calc(100% - 20px);
    height: 15px;
    left: 10px;
  }

  &--right {
    top: 10px;
    width: 15px;
    height: calc(100vh - 20px);
    right: 10px;
  }
}
