.Intro {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  //align-items: center;
  width: 100%;
  min-height: 100dvh;
  height: auto;
  margin-top: 25px;

  &-flowers {
    position: absolute;
    z-index:1;
    width: 100%;
    bottom: -0px;


    @media only screen and (min-width: 640px) {
      width: 50%;
    }

    @media only screen and (max-width: 1024px) {
      right: 0;
      left: 0;
      margin: 0 auto;
    }

    @media only screen and (min-width: 1024px) {
      width: 55%;
     bottom: -0px;
    }
  }

  &-container {
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media only screen and (min-width: 640px) {
      width: 95%;
    }

    @media only screen and (min-width: 1024px) {
      flex-flow: row nowrap;
      flex-direction: row;
      justify-content: center;
    }
  }

  // Not present?
  &-photo {
    margin-top: 0px;
    width: 250px;
    height: 250px;
    border-radius: 250px;
    //background: url('/media/new-1.jpg') no-repeat center 70% / cover;

    @media only screen and (min-width: 1024px) {
      width: 450px;
      height: 450px;
      border-radius: 450px;
      margin-top: -25px;
    }

    @media only screen and (min-width: 1440px) {
      width: 600px;
      height: 600px;
      border-radius: 600px;
      margin-top: -25px;
    }
  }

  &-text-wrapper {
    //position: absolute;
    //text-align: center;
    // background-color: red;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: -15px;

    @media only screen and (min-width: 1024px) {
      margin-top: -200px;
      width: 60%;
    }
  }

  &-image-wrapper {
    //background-color: green;
    position: relative;
    display: block;
    width: 98%;
    height: 50vh;

    @media only screen and (min-width: 800px) {
      width: 50%;
    }

    @media only screen and (min-width: 1024px) {
      width: 40%;
      height: 100vh;
    }
  }

  &-banner {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  // Not present ?
  &-pablo {
    position: absolute;
    bottom: -70px;
    right: -150px;
    height: auto;
    width: 450px;
  }

  &-title-heart {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-top: 15px;
    margin-left: 10px;

    @media only screen and (min-width: 800px) {
      width: 50px;
      height: 50px;
    }

    @media only screen and (min-width: 1024px) {
      margin-top: -55px;
      margin-left: -55px;
    }
  }

  &-title {
    color: $primary-color;
    //color: #9d5a4d;

    @include main-title();

    text-align: center;
    transform: rotate(-10deg);

    @media only screen and (min-width: 1280px) {
      font-size: 22rem;
    }
  }

  &-breakpoint-1 {
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  &-subtitle {
    @include headline-h1();

    color: $color-primary-green;
    text-align: center;
    margin: 0;
    margin-top: 35px;
    font-size: 4.5rem;

    @media only screen and (min-width: 1280px) {
      margin-top: 70px;
      letter-spacing: 15px;
      font-size: 9rem;
    }
  }

  &-date {
    color: #fa7264;
    text-align: center;
    margin: 25px 0px;

    @include lead-text();
    font-weight: 700;
    font-size: 2rem;
    letter-spacing: 5px;

    @media only screen and (min-width: 1280px) {
      margin: 25px 0px;
      margin-top: 50px;

      font-size: 4rem;
    }
  }

  &-address {
    color: $color-primary-green;
    text-align: center;
    margin: 0;
    margin-bottom: 25px;
    letter-spacing: 2.5px;
    text-decoration: underline;

    @include auxiliary-text();
    line-height: 2.5rem;

    @media only screen and (min-width: 1280px) {
      letter-spacing: 5px;
      font-size: 2.5rem;
      line-height: 4rem;
    }
  }

  // Not present ?
  &-svg {
    width: 100%;
    height: 25%;
    transform: scaleY(-1);

    @media only screen and (min-width: 1024px) {
      height: 45%;
    }
  }

  &-arrow {
    display: none;
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    transform: rotate(90deg);
    width: 20px;
    height: auto;
    stroke: #fff;

    @media only screen and (min-width: 1024px) {
      display: block;
      width: 25px;
      bottom: 5px;
      height: auto;
      opacity: 0.8;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  @media only screen and (min-width: 640px) {
   
  }

  @media only screen and (min-width: 1024px) {
    justify-content: space-around;
    margin-top: 0;
  }
}
