.BoardMood {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  //align-items: center;
  width: 100%;
  // min-height: 100vh;
  height: auto;

  &-flowers-1 {
    position: absolute;
    top:-15px;
    left: -10px;
    z-index: 1;
    height: 200px;
    width: auto;
    transform: rotate(45deg);

    @media only screen and (min-width: 1024px) {
      top:-55px;
      height: 600px;
    }

    @media only screen and (min-width: 1284px) {
      top:25px;
      height: 600px;
    }
  }

  &-flowers-2 {
    position: absolute;
    bottom: -100px;
    right: -50px;
    z-index: 1;
    height: 200px;
    width: auto;
    transform: rotate(-45deg);

    @media only screen and (min-width: 1024px) {
      bottom: -150px;
      right: -100px;
      height: 400px;
    }

    @media only screen and (min-width: 1284px) {
      bottom: -150px;
      right: -50px;
      height: 500px;
    }
  }

  &-line {
    position: absolute;
    z-index: 1;

    height: 70px;
    right: 70px;
    top: 35px;

    @media only screen and (min-width: 600px) {
      height: 70px;
      right: 180px;
      top: 30px;
    }

    @media only screen and (min-width: 1024px) {
      height: 100px;
      right: 280px;
      top: 40px;
    }

    @media only screen and (min-width: 1284px) {
      height: 250px;
      //right: 250px;
      right: 200px;
      top: 80px;
    }
  }

  &-container {
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media only screen and (min-width: 640px) {
      width: 85%;
    }
  }

  &-text {
    position: relative;
    width: 98%;
    margin: 0 auto;
    margin-top: 25px;
  }

  &-title {
    @include title-h2();

    color: $color-primary;
  }

  &-subtitle {
    @include title-h3();
  }

  &-picture {
    position: relative;
    width: 100%;
    max-width: 1280px;
    height: auto;
  }
}
