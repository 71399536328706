*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
  margin: 0px;
  padding: 0px;
}

body {
  overflow-x: hidden !important;
  background-color: $color-background;
}

body,
canvas,
section,
aside,
header,
main,
footer,
video,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
button,
label {
  text-rendering: optimizeLegibility;
}

a {
  text-decoration: none;
}

a,
a:-webkit-any-link,
button,
input {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
}

input[data-autocompleted] {
  background-color: transparent !important;
}
