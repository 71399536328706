.Fiesta {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  width: 100%;
  //min-height: 100vh;
  height: auto;

  &-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media only screen and (min-width: 640px) {
      width: 100%;
    }
  }

  &-flex {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    background-color: transparent;
    padding-top: 25px;
    width: 100%;

    @media only screen and (min-width: 1024px) {
      flex-flow: row nowrap;
      padding-top: 0;

      //background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &-text {
    position: relative;

    width: 95%;
    margin: 0 auto;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @media only screen and (min-width: 600px) {
      width: 400px;
    }

    @media only screen and (min-width: 1024px) {
      margin-left: 50px;
      justify-content: center;
      width: 50%;
    }
  }

  &-title {
    @include title-h2();

    background-color: #e7eee4;
    border-radius: 15px;
    padding: 10px 25px;

    @media only screen and (min-width: 1024px) {
      padding: 15px 35px;
      border-radius: 35px;
    }
  }

  &-subtitle {
    @include title-h3();
  }

  &-details {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
    height: auto;
    align-items: center;
    margin-bottom: 25px;

    &-icon {
      position: relative;
      width: 70px;
      height: 70px;
      margin-right: 15px;
    }

    &-text {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      height: auto;
    }

    &-title {
      @include med-text();
      font-weight: 300;
      margin: 0;
      margin-top: 10px;
      font-size: 1.8rem;
    }

    &-subtitle {
      @include body-text();
      color: rgb(29, 27, 27);
      text-transform: uppercase;
      margin: 0;
    }

    @media only screen and (min-width: 1024px) {
      width: 100%;
      height: 50px;
      margin-bottom: 120px;

      &-title {
        font-size: 4rem;
      }

      &-icon {
        width: 120px;
        height: 120px;
      }
    }
  }

  &-details-last {
    @media only screen and (min-width: 1024px) {
      margin-bottom: 0;
    }
  }

  &-map {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    background: url('/media/new-2/map.png') no-repeat center center / cover;
    height: 50vh;

    @media only screen and (min-width: 1024px) {
      width: 50%;
      height: 100vh;
    }
  }

  &-motive-down-left {
    position: absolute;
    z-index: 1;
    bottom: 55%;
    left: -250px;
    transform: rotate(30deg);

    @media only screen and (min-width: 640px) {
      left: -200px;
    }

    @media only screen and (min-width: 1024px) {
      bottom: -110px;
    }

    @media only screen and (min-width: 1285px) {
      bottom: -110px;
      left: -100px;
    }
  }

  &-motive-down-right {
    position: absolute;
    z-index: 1;
    bottom: 50%;
    right: -100px;
    width: 150px;
    height: auto;

    @media only screen and (min-width: 640px) {
      width: 250px;
      bottom: 45%;
      right: -100px;
    }

    @media only screen and (min-width: 1024px) {
      width: 300px;
      height: auto;
      bottom: -100px;
      right: -200px;
    }
  }
  &-motive-top-right {
    position: absolute;
    z-index: 1;
    top: -50px;
    right: -125px;
    width: 200px;
    height: auto;

    @media only screen and (min-width: 640px) {
      width: 250px;
      top: -100px;
      right: -150px;
    }

    @media only screen and (min-width: 1024px) {
      width: 300px;
      height: auto;
      top: -150px;
      right: -200px;
    }

    @media only screen and (min-width: 1285px) {
    }
  }
}
