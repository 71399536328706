.FormEl {
  position: relative;
  width: 100%;
  height: auto;
  // min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  // background-color: #ddc6b0;
  padding-bottom: 50px;

  @media only screen and (min-width: 1280px) {
    min-height: auto;
  }

  &-container {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    max-width: 900px;
    
    margin: 0 auto;

    @media only screen and (min-width: 1024px) {
      width: 80%;
    }
  }

  &-title {
    @include title-h2();
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;

    color: $color-primary;

    @media only screen and (min-width: 1024px) {
      //margin-bottom: 50px;
    }
  }

  &-svg {
    width: 100%;
    height: 50%;
    transform: scaleY(-1.1);

    @media only screen and (min-width: 1024px) {
      height: 35%;
    }
  }

  &-form {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: auto;
    align-items: center;
  }

  &-input,
  &-textarea {
    background-color: #e7eee4 !important;
    box-shadow: none;
    width: 80%;
    overflow: hidden;
    border: none;
    text-align: center;
    color: $primary-color !important;
    height: 50px;
    margin-bottom: 35px;
    padding: 15px 35px;
    border-radius: 15px;

    @include med-text();

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $primary-color;
      opacity: 1;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      border: none;
      -webkit-text-fill-color: $primary-color;
      -webkit-box-shadow: none;
      -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
      box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    @media only screen and (min-width: 1024px) {
      width: 400px;
    }
  }

  &-textarea-title {
    width: 80%;
    @include title-h3();

    text-align: left;
    line-height: 2rem;

    span {
      @include secondary-body();
    }

    @media only screen and (min-width: 1024px) {
      width: 400px;
    }
  }

  &-textarea {
    background-color: #fff;
  }

  &-button {
    width: 80%;
    height: 50px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    color: #f2f2f2 !important;
    margin-top: 25px;
    cursor: pointer;
    background-color: $color-primary-green;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 2px 6px rgba(100, 100, 100, 0.3);
    border-radius: 25px;
    border-color: transparent;

    @include med-text();

    &:hover {
      background-color: $color-primary-green;
      color: #f2f2f2 !important;
      box-shadow: 0px 6px 12px rgba(100, 100, 100, 0.3);
    }

    &:focus {
      outline: none;
    }

    @media only screen and (min-width: 1024px) {
      width: 400px;
    }
  }

  &-success {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 1024px) {
      width: 100%;
    }
  }

  &-subtitle {
    @include title-h3();

    margin-top: 50px;

    @media only screen and (min-width: 1024px) {
      margin-top: 100px;
      font-size: 2rem;
    }
  }

  &-success-link {
    margin-top: 15px;
    @include body-text();
    font-weight: 700;
    letter-spacing: 1.8px;

    display: flex;
    flex-flow: row no wrap;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: $primary-color;
    width: 280px;
    height: 45px;
    border-radius: 25px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 2px 6px rgba(100, 100, 100, 0.3);

    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
      color: $primary-color;
      box-shadow: 0px 6px 12px rgba(100, 100, 100, 0.3);
    }

    &:focus {
      outline: none;
    }

    img {
      margin-right: 10px;
    }
  }

  textarea {
    min-height: 100px;
  }
}
