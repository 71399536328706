.Video {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  //align-items: center;
  width: 100%;
  //min-height: 200vh;
  height: auto;
  overflow: hidden;
  background-color: #ddc6b0;

  &-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media only screen and (min-width: 640px) {
      width: 85%;
    }
  }

  &-text {
    position: relative;
    width: 98%;
    margin: 0 auto;
    margin-top: 25px;
  }

  &-title {
    @include title-h2();
  }

  &-subtitle {
    @include title-h3();
  }

  &-video {
    @media only screen and (min-width: 640px) {
      margin-top: 100px;
    }

    video {
      width: 100%;
      height: auto;
    }
  }

  &-link-wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: auto;
    min-height: 200px;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
    //margin-top: 25px;

    @media only screen and (min-width: 1024px) {
      width: 600px;
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  &-link {
    @include body-text();
    font-weight: 700;
    letter-spacing: 1.8px;

    display: flex;
    flex-flow: row no wrap;
    align-items: center;
    justify-content: center;
    background-color: #25d366;
    color: #fff;
    width: 250px;
    height: 45px;
    border-radius: 25px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 2px 6px rgba(100, 100, 100, 0.3);

    &:hover,
    &:active,
    &:focus {
      background-color: #22c45d;
      color: #fff;
      box-shadow: 0px 6px 12px rgba(100, 100, 100, 0.3);
    }

    &:focus {
      outline: none;
    }

    img {
      margin-right: 10px;
    }
  }

  &-link-tel {
    background-color: #fff;
    color: $primary-color;

    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
      color: $primary-color;
      box-shadow: 0px 6px 12px rgba(100, 100, 100, 0.3);
    }
  }
}
