.main-content {
  display: block;
  margin: 0px;
  min-height: 100vh;
  height: auto;
  padding: 0px;
  position: relative;
  width: 100%;
  background-color: $color-background;
  overflow-x: hidden !important;
  //background-image: url('/media/cream-pixels.png');
  //background-image: url('/media/background_beige_pattern.png');
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}
