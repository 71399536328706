// Fonts
$font-stack: 'Roboto slab', serif;
$font-title: 'Calistoga', serif;
// Colors
$primary-color: #272727;
$secundary-color: #ffffff;
$accent-color: #37c871;
$grey-color: #a0a0a0;
$grey-color-dark: #333333;
$grey-color-hover: #4a4a4a;
$box-shadow: 0 16px 28px 0;
$pink-color: #f2e0dc;
$blue-color: #7c849d;
$green-color: #e1efe3;
$second-green: #cfe4d0;
$third-green: #d8eced;

// New Colors
$color-primary: rgb(22, 21, 19);
$color-secundary: rgb(248, 236, 222);
$color-accent: #a7816a;
$color-background: #f2f2f2;
$color-primary-green: #537a43;
// Typography
@mixin main-title() {
  font-size: 8.5rem;
  line-height: 5rem;
  font-family: $font-title;
  font-weight: 400;

  @media only screen and (min-width: 1024px) {
    font-size: 15rem;
    line-height: 10.2rem;
  }
}

@mixin headline-h1() {
  font-size: 3.2rem;
  line-height: 3.4rem;
  font-family: $font-title;
  font-weight: bold;
}

@mixin title-h2() {
  font-size: 3.8rem;
  line-height: 4.8rem;
  font-family: $font-title;
  font-weight: bold;
  text-align: center;
  color: $color-primary-green;
  @media only screen and (max-width: 640px) {
    font-size: 2.8rem;
    line-height: 3.7rem;
  }

  @media only screen and (min-width: 641px) and (max-width: 1024px) {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 8.5rem;
    line-height: 9.5rem;
  }
}

@mixin title-h3() {
  font-size: 2rem;
  line-height: 3rem;
  font-family: $font-stack;
  font-weight: 400;
  text-align: center;
  //color: #9d5a4d;
  color: $primary-color;
  letter-spacing: 3px;

  @media only screen and (max-width: 640px) {
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 1.8px;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 2rem;
    line-height: 4rem;
    letter-spacing: 4px;
  }
}

@mixin title-h4() {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: $font-stack;
  font-weight: 700;
}

@mixin lead-text() {
  font-size: 1.8rem;
  line-height: 2rem;
  font-family: $font-title;
  font-weight: 400;

  @media only screen and (min-width: 1024px) {
    font-size: 2.5rem;
    line-height: 2.7rem;
  }
}

@mixin med-text() {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: $font-stack;
  font-weight: 400;

  @media only screen and (min-width: 1024px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

@mixin body-text() {
  font-size: 1.5rem;
  line-height: 1.7rem;
  font-family: $font-stack;
  font-weight: 400;

  @media only screen and (min-width: 1440px) {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  @media only screen and (min-width: 1640px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

@mixin auxiliary-text() {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-family: $font-stack;
  font-weight: 400;
}

@mixin body-link() {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: $font-stack;
  font-weight: 400;
}

@mixin title-link() {
  font-size: 2.4rem;
  line-height: 2.6rem;
  font-family: $font-stack;
  font-weight: 700;
}

@mixin secondary-body() {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-family: $font-stack;
  font-weight: 400;
}
