.Gifts {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  //align-items: center;
  width: 100%;
  //min-height: 100vh;
  height: auto;

  &-container {
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media only screen and (min-width: 640px) {
      width: 85%;
    }
  }

  &-text {
    position: relative;
    width: 98%;
    margin: 0 auto;
  }

  &-title {
    @include title-h2();

    color: $color-primary;
  }

  &-subtitle {
    @include title-h3();
  }

  &-picture {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: auto;
  }

  &-picture-2 {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: auto;
  }

  &-payment-wrapper {
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;

    @media only screen and (min-width: 1024px) {
      
    }
  }

  &-payment {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    width: 90%;

    @media only screen and (min-width: 1024px) {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 500px;
    }
  }

  &-mbway-1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    @media only screen and (min-width: 1024px) {
      opacity: 0.9;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  &-mbway {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 25px;
    width: 100%;
  }

  &-mbway-icon {
    border-radius: 5px;
    margin-right: 10px;
    width: 36px;
    height: auto;
  }

  &-mbway-subtitle {
    font-weight: 700;

    @media only screen and (max-width: 640px) {
      font-size: 1.6rem;
      line-height: 2rem;
      letter-spacing: 1.8px;
    }

    @media only screen and (min-width: 641px) {
      font-size: 1.6rem;
      line-height: 3.6rem;
      letter-spacing: 3px;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 2rem;
    }
  }

  &-subtitle-iban {
    font-weight: 700;
    cursor: pointer;

    @media only screen and (max-width: 640px) {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 1px;
    }

    @media only screen and (min-width: 1024px) {
      ont-size: 2rem;

      opacity: 0.9;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }

    span {
      font-weight: 700;
      @media only screen and (min-width: 640px) {
        font-size: 1.6rem;
        line-height: 3.6rem;
        letter-spacing: 3px;
      }

      @media only screen and (min-width: 1024px) {
        ont-size: 2rem;
      }
    }
  }

  &-copy {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 25px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }

    img {
      width: 16px;
      height: 16px;
    }

    span {
      @include secondary-body();
      font-size: 1rem;
      color: $grey-color-dark;
    }
  }

  &-iban {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    justify-content: space-between;
    width: 90%;

    @media only screen and (min-width: 1024px) {
      width: 80%;
    }
  }
}
